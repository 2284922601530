<template lang="pug">
    .list-items-wrap(:class="{ dense }")
        .item-wrap(
            v-for="(item, index) in renderData",
            :key="`${item.type}__${index}`"
        )
            .inner-wrap(v-if="item.type === 'icon'")
                article-icon.icon(src="hex")
            .inner-wrap(v-else-if="item.type === 'content'")
                .image-wrap
                    img(:src="item.icon")
                    ui-paragraph.text(v-html="item.text")
</template>

<script>
import ArticleIcon from '../../../../Icon.vue';
import UiParagraph from '../../../../../ui/Paragraph.vue';

const createContentBlock = ({ icon, text }) => ({
    type: 'content',
    icon,
    text,
});

const createIconBlock = () => ({
    type: 'icon',
});

const appendIcon = (index, length, isMobile) => {
    if (isMobile || index === length - 1) return false;

    const remainderIndex = (index + 1) % 3;

    return remainderIndex !== 0;
};

export default {
    name: 'list-items-part-5',
    components: {
        ArticleIcon,
        UiParagraph,
    },
    props: {
        itemsData: {
            type: Array,
            required: true,
        },
        dense: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        renderData() {
            const { isMobile } = this.$platform;

            return this.itemsData.flatMap((data, index, arr) => ([
                createContentBlock(data),
                appendIcon(index, arr.length, isMobile) ? createIconBlock() : {},
            ]));
        },
    },
};
</script>

<style lang="scss">
.list-items-wrap {
    width: 100%;
    max-width: 750px;
    margin: rem(90) auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: flex-start;

    .item-wrap {
        margin: rem(64) 0;

        .inner-wrap {
            .icon {
                width: rem(32);
                height: rem(26);
                margin: 0 rem(50);

                @media (--viewport-laptop-m) {
                    margin: 0 rem(80);
                }
            }

            .image-wrap {
                width: rem(110);
                height: rem(92);
                position: relative;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }

                .text {
                    width: 120%;
                    position: absolute;
                    top: 110%;
                    left: -10%;
                    text-align: center;
                }
            }
        }
    }

    &.dense {
        .item-wrap {
            margin: rem(40) 0;
        }
    }

    @media (--viewport-tablet) {
        width: 100%;
        max-width: unset;
        margin: rem-mobile(64) auto;
        padding: 0 var(--offset-x);
        display: block;

        .item-wrap {
            margin: rem-mobile(48) auto;

            .inner-wrap {
                .image-wrap {
                    width: 45%;
                    height: unset;
                    margin: 0 auto;

                    .text {
                        width: 100%;
                        margin: rem-mobile(24) auto;
                        position: static;
                    }
                }
            }
        }

        &.dense {
            .item-wrap {
                margin: rem-mobile(48) auto;
            }
        }
    }
}
</style>
