<template lang="pug">
    list-items-part-5(:itemsData="itemsData")
</template>

<script>
import ListItemsPart5 from './ListItems/ListItems.vue';

// eslint-disable-next-line
const getIcon = (src) => require(`../../../../../assets/images/articles/5/list_items/bottom/${src}.svg`);

export default {
    name: 'list_items_bottom',
    components: {
        ListItemsPart5,
    },
    computed: {
        itemsData() {
            return [
                {
                    icon: getIcon('1'),
                    text: 'минимизация контактов с&nbsp;больными туберкулезом в&nbsp;активной форме',
                },
                {
                    icon: getIcon('2'),
                    text: 'соблюдение общих мер гигиены',
                },
                {
                    icon: getIcon('3'),
                    text: 'проветривание и&nbsp;уборка помещений',
                },
                {
                    icon: getIcon('4'),
                    text: 'соблюдение режима труда и&nbsp;отдыха',
                },
                {
                    icon: getIcon('5'),
                    text: 'сбалансированная диета',
                },
                {
                    icon: getIcon('6'),
                    text: 'отказ от&nbsp;курения, алкоголя и&nbsp;наркотиков',
                },
                {
                    icon: getIcon('7'),
                    text: 'физкультура',
                },
                {
                    icon: getIcon('8'),
                    text: 'прогулки на&nbsp;свежем воздухе',
                },
            ];
        },
    },
};
</script>
